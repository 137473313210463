import classNames from 'classnames';
import { Heading, Paragraph } from '@/components/1-atoms';
import styles from './NoticeItem.module.scss';

import { formatDateToDayAndYear } from '@/utilities/dateHandler';
import { ReactComponent as CalendarIcon } from '@/assets/Icons/icon-calendar.svg';

export interface NoticeItemProps {
  className?: string;
  notice: TenderNotice;
}

export const NoticeItem = ({ className, notice }: NoticeItemProps) => {
  return (
    <div className={classNames(styles.NoticeItem, className)}>
      <div className={styles.NoticeItem__heading}>
        <Heading headingLevel={'h3'} className={styles.NoticeItem__title}>
          {notice.event.name}
        </Heading>

        <div className={styles.NoticeItem__dateWrapper}>
          <CalendarIcon />
          <Paragraph className={styles.NoticeItem__date}>
            <strong>Date:</strong> {formatDateToDayAndYear(new Date(notice.createdAtDate))}
          </Paragraph>
        </div>
      </div>

      <div className={styles.NoticeItem__description}>
        {notice.message.split('\n').map((line, index) => (
          <Paragraph key={index}>{line}</Paragraph>
        ))}
      </div>
    </div>
  );
};
