// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EApr4g_AkC0oq_LitCgk {
  margin-bottom: 2rem;
}

.E4InSOa46g2xgghw2Mpa {
  align-self: center;
}

.sX_Qyj9FaV2gisOMC596 {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  flex-direction: row;
}

.MmN10Q2iu98JSDvRldPL {
  width: 70%;
}

.s15qqPmvwC9qR_veya2K {
  margin-top: 2rem;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/DashBoard/CreateOrganisationForm/CreateOrganisationStep/CreateOrganisationStep.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".CreateOrganisationStep__heading {\n  margin-bottom: 2rem;\n}\n\n.CreateOrganisationStep__submitBtn {\n  align-self: center;\n}\n\n.CreateOrganisationStep__orgNumber {\n  display: flex;\n  align-items: center;\n  gap: 2.4rem;\n  flex-direction: row;\n}\n\n.CreateOrganisationStep__select {\n  width: 70%;\n}\n\n.CreateOrganisationStep__error {\n  margin-top: 2rem;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateOrganisationStep__heading": `EApr4g_AkC0oq_LitCgk`,
	"CreateOrganisationStep__submitBtn": `E4InSOa46g2xgghw2Mpa`,
	"CreateOrganisationStep__orgNumber": `sX_Qyj9FaV2gisOMC596`,
	"CreateOrganisationStep__select": `MmN10Q2iu98JSDvRldPL`,
	"CreateOrganisationStep__error": `s15qqPmvwC9qR_veya2K`
};
export default ___CSS_LOADER_EXPORT___;
