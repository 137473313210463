import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';

import { Paragraph } from '@/components/1-atoms';
import { Button, WelcomeCard } from '@/components/2-molecules';
import { TasksCalendar, TasksOverview } from '@/components/3-organisms';
import { TendersLayout } from '@/components/4-templates';

import styles from './DashBoardPage.module.scss';
import { useCustomerProfile } from '@/hooks/useCustomerProfile';

export const DashBoardPage = () => {
  const dispatch = useAppDispatch();

  const { dispatchCustomerProfile, selectCustomerProfile, isBuyerProfile } = useCustomerProfile();

  useEffect(() => {
    dispatch(setTitle('overview'));
    dispatchCustomerProfile();
  }, []);

  const profileOverview = selectCustomerProfile();

  const firstName = useAppSelector((state) => state.user.loginProfile?.user?.firstName);

  if (!isBuyerProfile(profileOverview)) return;

  const { dashboardOverview } = profileOverview;

  const { numberOfNewMessages, numberOfExpiredContracts, numberOfNewResponses, tasks } =
    dashboardOverview || {};

  return (
    <TendersLayout>
      <section className={styles.DashBoardPage}>
        <>
          <WelcomeCard title={`Welcome ${firstName}!`}>
            <Paragraph className={styles.WelcomeCard__content}>
              {numberOfNewMessages && `You have ${numberOfNewMessages} new messages`}
              {numberOfExpiredContracts && (
                <>
                  ,
                  <br />
                  You have {numberOfExpiredContracts} contracts expiring
                </>
              )}
              {numberOfNewResponses && (
                <>
                  ,
                  <br />
                  You have {numberOfNewResponses} new responses
                </>
              )}
            </Paragraph>
          </WelcomeCard>

          <div className={styles.DashBoardPage__createTenderCta}>
            <Button link='/tenders/tendersOverview/new-tender' type={'primary'}>
              Create New Tender
            </Button>
          </div>

          <div className={styles.DashBoardPage__eventsSection}>
            <TasksOverview tasks={tasks} />

            <TasksCalendar tasks={tasks} className={styles.DashBoardPage__calendar} />
          </div>
        </>
      </section>
    </TendersLayout>
  );
};
