import classNames from 'classnames';
import styles from './FormErrorMessage.module.scss';
import { Paragraph } from '@/components/1-atoms';

export interface FormErrorMessageProps {
  className?: string;
  id: string;
  children?: React.ReactNode;
}

export const FormErrorMessage = ({ id, className, children }: FormErrorMessageProps) => {
  return (
    <div id={id} className={classNames(styles.FormErrorMessage, className)}>
      <Paragraph>{children}</Paragraph>
    </div>
  );
};
