import { Controller, useFormContext } from 'react-hook-form';

import { Heading, Paragraph } from '@/components/1-atoms';
import {
  FormFieldCta,
  FormFieldInput,
  FormFieldRadio,
  FormFieldSelect,
} from '@/components/2-molecules';

import styles from './CreateOrganisationStep.module.scss';
import { useEffect, useState } from 'react';
import { GetAllCountryCodes } from '@/services/OrganisationServices';
import { FormErrorMessage } from '@/components/2-molecules/Form/FormErrorMessage';

export const CreateOrganisationStep = () => {
  const radioOptions = [
    { value: 'Buyer', label: 'Buyer' },
    { value: 'Supplier', label: 'Supplier' },
  ];

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const [countryCodes, setCountryCodes] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>();

  useEffect(() => {
    async function getCountryCode() {
      const response = await GetAllCountryCodes();
      await setCountryCodes(response?.result);
    }
    getCountryCode();
  }, []);

  return (
    <>
      <Heading className={styles.CreateOrganisationStep__heading} headingLevel={'h3'}>
        We need to ask you just a few questions before you <br /> start using the system so we can
        set up your account
      </Heading>

      <Controller
        control={control}
        name={'organisationType'}
        render={({ field: { onChange, value } }) => (
          <FormFieldRadio
            title='How do you want to use the system?'
            name={'organisationType'}
            options={radioOptions}
            optionsGap='20rem'
            defaultSelectedValue={value}
            onChange={(value) => onChange(value)}
            state={{ required: true }}
          />
        )}
      />

      <div>
        <Heading headingLevel='h3' className={styles.CreateOrganisationStep__heading}>
          {'Please enter your country code and organisation number:'}
        </Heading>

        <div className={styles.CreateOrganisationStep__orgNumber}>
          <Controller
            control={control}
            name={'organisationJurisdictionId'}
            render={({ field: { onChange, value } }) => (
              <FormFieldSelect
                className={styles.CreateOrganisationStep__select}
                id='organisationJurisdictionId'
                placeholder='Country Code'
                options={
                  countryCodes?.map((item) => ({
                    value: item.id,
                    label: item.countryCode + ' - ' + item.country,
                  })) ?? []
                }
                onChange={(value) => {
                  onChange(value);
                  setSelectedCountry(countryCodes?.find((item) => item.id == value));
                }}
                state={{ required: true }}
                defaultValue={value && value}
              />
            )}
          />

          <FormFieldInput
            id={'organisationNo'}
            name={'organisationNo'}
            type={'text'}
            register={register}
            state={{ required: true, disabled: !selectedCountry }}
            pattern={selectedCountry?.regEx}
            placeholder={
              selectedCountry?.example
                ? `Example: ${selectedCountry?.example}`
                : 'Organisation Number'
            }
          />
        </div>
        {errors?.organisationNo && (
          <FormErrorMessage className={styles.CreateOrganisationStep__error} id={'organisationNo'}>
            Organisation Number is invalid
          </FormErrorMessage>
        )}
      </div>

      <FormFieldCta
        className={styles.CreateOrganisationStep__submitBtn}
        type='submit'
        id={'save'}
        label={'Submit'}
      />
    </>
  );
};
