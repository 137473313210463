import { useState } from 'react';
import classNames from 'classnames';
import { formatDateToDayAndMonth } from '@/utilities/dateHandler';
import { TasksList } from './TasksList';
import { AnchorLink, Heading, Image, Paragraph, TextBox } from '@/components/1-atoms';
import { ContentCard, Filters } from '@/components/2-molecules';
import styles from './TasksOverview.module.scss';
import EmptyTasksImage from '@/assets/Images/image-empty-tasks.svg';

export interface TasksOverviewProps {
  className?: string;
  tasks?: TenderTasks[];
}

export const TasksOverview = ({ className, tasks }: TasksOverviewProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('tender');
  const handleFilterOptionChange = (value: string) => {
    setSelectedFilter(value);
  };

  const [visibleCount, setVisibleCount] = useState(3);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const shouldRenderLoadMore = tasks && tasks?.length > visibleCount;

  return (
    <div className={classNames(styles.TasksOverview, className)}>
      <ContentCard
        heading={
          <Heading headingLevel={'h3'}>{`Your tasks - ${formatDateToDayAndMonth(
            new Date(),
          )}`}</Heading>
        }
        actionButton={
          <div className={styles.TasksOverview__filters}>
            <Paragraph>Filters:</Paragraph>
            <Filters
              onChange={handleFilterOptionChange}
              options={[
                {
                  label: 'Tenders',
                  value: 'tender',
                  className: styles.TasksOverview__tendersFilter,
                  activeClassName: styles.TasksOverview__tendersFilter_active,
                },
                {
                  label: 'Contracts',
                  value: 'contract',
                  className: styles.TasksOverview__contractsFilter,
                  activeClassName: styles.TasksOverview__contractsFilter_active,
                },
                {
                  label: 'Suppliers',
                  value: 'supplier',
                  className: styles.TasksOverview__suppliersFilter,
                  activeClassName: styles.TasksOverview__suppliersFilter_active,
                },
              ]}
              selectedValue={selectedFilter}
              type={'rounded'}
            />
          </div>
        }
      >
        {tasks && tasks.length > 0 ? (
          <TasksList
            events={tasks?.slice(0, visibleCount)}
            handleLoadMore={handleLoadMore}
            shouldRenderLoadMore={shouldRenderLoadMore}
          />
        ) : (
          <div className={styles.TasksOverview__emptyList}>
            <Image src={EmptyTasksImage} alt={'Empty Tasks Image'} />
            <TextBox
              title={
                <Heading headingLevel={'h3'} className={styles.TasksOverview__emptyListHeading}>
                  Your task list is empty
                </Heading>
              }
              description={<>You don’t have any active tasks right now.</>}
              alignment={'centre'}
            />
          </div>
        )}
      </ContentCard>
    </div>
  );
};
