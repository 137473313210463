import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Button, FormFieldInput, FormFieldSelect, FormFieldRadio } from '@/components/2-molecules';
import classNames from 'classnames';
import styles from './HeadingForm.module.scss';
import { useAppDispatch } from '@/store';
import { createPhase, updatePhase } from '@/store/slices/createTenderSlice';

export interface HeadingFormProps {
  className?: string;
  currentPhaseName: string;
  stopEditing: () => void;
}

export const HeadingForm = ({ className, stopEditing, currentPhaseName }: HeadingFormProps) => {
  const { register, control, getValues, setValue, getFieldState, watch } = useFormContext();

  const dispatch = useAppDispatch();
  const startDate = watch(`${currentPhaseName}.startDate`);

  const options = [
    { value: 1, label: 'Visible to all' },
    { value: 2, label: 'Hidden' },
  ];

  const radioOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  return (
    <div className={classNames(styles.HeadingForm, className)}>
      <FormFieldInput
        className={styles.HeadingForm__input}
        id={`${currentPhaseName}.phaseName`}
        name={`${currentPhaseName}.phaseName`}
        label={'Phase name'}
        type={'text'}
        register={register}
        state={{ required: true }}
      />
      <FormFieldInput
        className={styles.HeadingForm__input}
        id={`${currentPhaseName}.startDate`}
        name={`${currentPhaseName}.startDate`}
        label={'Start date'}
        type={'date'}
        register={register}
        state={{ required: true }}
      />
      <FormFieldInput
        className={styles.HeadingForm__input}
        id={`${currentPhaseName}.endDate`}
        name={`${currentPhaseName}.endDate`}
        label={'End date'}
        type={'date'}
        register={register}
        state={{ required: true }}
        min={startDate}
      />

      <Controller
        control={control}
        name={`${currentPhaseName}.isMandatory`}
        render={({ field: { onChange, value } }) => (
          <FormFieldRadio
            className={styles.HeadingForm__radio}
            title='Mandatory?'
            name={`${currentPhaseName}.isMandatory`}
            options={radioOptions}
            defaultSelectedValue={value ? 'yes' : 'no'}
            onChange={(value) => (value === 'yes' ? onChange(true) : onChange(false))}
          />
        )}
      />

      <Controller
        control={control}
        name={`${currentPhaseName}.phaseVisabilityId`}
        render={({ field: { onChange, value } }) => (
          <FormFieldSelect
            label='Visibility'
            className={styles.HeadingForm__select}
            id={`${currentPhaseName}.phaseVisabilityId`}
            defaultValue={value ? 'visibleToAll' : 'hidden'}
            onChange={(value) => onChange(value)}
            options={options}
            state={{ required: true }}
          />
        )}
      />

      <Button
        className={styles.HeadingForm__submitBtn}
        type={'primaryColor'}
        onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
          event && event.preventDefault();

          setValue(currentPhaseName, getValues(currentPhaseName), {
            shouldValidate: true,
          });

          if (!getFieldState(currentPhaseName).invalid) {
            const phaseData = getValues(currentPhaseName);

            if (phaseData.phaseId) {
              const response = await dispatch(updatePhase(getValues(currentPhaseName)));
            } else if (!phaseData.phaseId) {
              const response = await dispatch(createPhase(getValues(currentPhaseName)));
              const phaseResponse = response.payload as { phaseId: number };
              setValue(`${currentPhaseName}.phaseId`, phaseResponse.phaseId);
            }

            await stopEditing();
          }
        }}
      >
        Save
      </Button>
    </div>
  );
};
