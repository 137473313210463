import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { CreateOrganisation, UpdateOrganisation } from '@/services/OrganisationServices';

import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';
import { getUserLogin, setAccessToken } from '@/store/slices/userLoginSlice';

import { ErrorCard, LoadingCard } from '@/components/2-molecules';
import { CreateOrganisationForm } from '@/components/3-organisms';
import { TendersLayout } from '@/components/4-templates';
import { LoginType } from '@/constants/loginTypes';

import styles from './OnBoardingPage.module.scss';
import { Heading } from '@/components/1-atoms';

export const OnBoardingPage = () => {
  const dispatch = useAppDispatch();

  const loginProfile = useAppSelector((state) => state.user?.loginProfile);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dispatch(setTitle('overview'));
  }, []);

  const dispatchCreateOrganisation = async (
    data: OrganisationDetails,
  ): Promise<OrganisationDetails> => {
    try {
      const { organisationType, organisationNo, organisationJurisdictionId } = data;
      const userId = loginProfile?.user?.userId ?? 0;
      return await CreateOrganisation(
        userId,
        organisationNo,
        organisationType,
        organisationJurisdictionId,
      );
    } catch (error) {
      setIsError(true);
      return Promise.reject(error);
    }
  };

  const dispatchUpdateOrganisation = async (data: OrganisationDetails) => {
    try {
      const response = await UpdateOrganisation(data);
      if (response?.successfulOperation) {
        await dispatch(getUserLogin());
        window.location.pathname = '/';
      } else console.log('error', response);
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <TendersLayout>
      <section className={styles.OnBoardingPage}>
        {/* loading */}
        {!isError && loginProfile?.version == LoginType.FirstLogin ? (
          <CreateOrganisationForm
            createOrganisation={dispatchCreateOrganisation}
            updateOrganisation={dispatchUpdateOrganisation}
          />
        ) : (
          <LoadingCard />
        )}

        {isError && (
          <ErrorCard>
            <Heading headingLevel='h3'>Something went wrong. Please try again later.</Heading>
          </ErrorCard>
        )}
      </section>
    </TendersLayout>
  );
};
