import { useFormContext } from 'react-hook-form';

import { FormFieldCta, FormFieldInput } from '@/components/2-molecules';
import { Heading } from '@/components/1-atoms';

import styles from './UpdateOrganisationStep.module.scss';
import { ReactComponent as MailIcon } from '@/assets/Icons/icon-mail.svg';
import { ReactComponent as PhoneIcon } from '@/assets/Icons/icon-phone.svg';
import { ReactComponent as AddressIcon } from '@/assets/Icons/icon-address.svg';
import { ReactComponent as BuildingIcon } from '@/assets/Icons/icon-building.svg';
import { ReactComponent as CoinsIcon } from '@/assets/Icons/icon-coins.svg';
import { ReactComponent as CompanyDetailsIcon } from '@/assets/Icons/icon-company-details.svg';
import { ReactComponent as FactoryIcon } from '@/assets/Icons/icon-factory.svg';
import { ReactComponent as PersonsIcon } from '@/assets/Icons/icon-persons.svg';
import { ReactComponent as PersonProfileIcon } from '@/assets/Icons/icon-person-profile.svg';

export const UpdateOrganisationStep = () => {
  const { register } = useFormContext();

  return (
    <section className={styles.UpdateOrganisationStep}>
      <div className={styles.UpdateOrganisationStep}>
        <Heading headingLevel='h2' className={styles.UpdateOrganisationStep__pageTitle}>
          Please complete the information below
        </Heading>

        <div className={styles.UpdateOrganisationStep__row}>
          <FormFieldInput
            id={'companyName'}
            label={'Company name'}
            type={'text'}
            name={'companyName'}
            register={register}
            icon={<BuildingIcon />}
            state={{ required: true }}
            // TODO: Add Disabled State and Error State
            // TODO: Add Disabled State and Error State
          />

          <FormFieldInput
            id={'organisationNo'}
            name={'organisationNo'}
            label={'Organisation Number'}
            type={'text'}
            register={register}
            icon={<CompanyDetailsIcon />}
            state={{ required: true }}

            // TODO: Add Disabled State and Error State
          />
        </div>
        <div className={styles.UpdateOrganisationStep__row}>
          <FormFieldInput
            id={'industry'}
            label={'Industry'}
            type={'text'}
            name={'industry'}
            register={register}
            icon={<FactoryIcon />}
            state={{ required: true }}

            // TODO: Add Disabled State and Error State
          />

          <FormFieldInput
            id={'address'}
            label={'Address'}
            type={'text'}
            name={'address'}
            register={register}
            icon={<AddressIcon />}
            state={{ required: true }}

            // TODO: Add Disabled State and Error State
          />
        </div>

        <div className={styles.UpdateOrganisationStep__row}>
          <FormFieldInput
            id={'contact.firstName'}
            label={'First name'}
            type={'text'}
            name={'contact.firstName'}
            register={register}
            icon={<PersonProfileIcon />}
            state={{ required: true }}

            // TODO: Add Disabled State and Error State
          />

          <FormFieldInput
            id={'contact.lastName'}
            label={'Last name'}
            type={'text'}
            name={'contact.lastName'}
            register={register}
            icon={<PersonProfileIcon />}
            state={{ required: true }}

            // TODO: Add Disabled State and Error State
          />
        </div>
        <div className={styles.UpdateOrganisationStep__row}>
          <FormFieldInput
            id={'contact.phoneNo'}
            label={'Phone'}
            name={'contact.phoneNo'}
            type={'tel'}
            register={register}
            icon={<PhoneIcon />}
            state={{ required: true }}

            // TODO: Add Disabled State and Error State
          />
          <FormFieldInput
            id={'organisationEmail'}
            label={'Email'}
            name={'organisationEmail'}
            type={'email'}
            register={register}
            icon={<MailIcon />}
            state={{ required: true }}
            // TODO: Add Disabled State and Error State
          />
        </div>

        <div className={styles.UpdateOrganisationStep__row}>
          <FormFieldInput
            id={'invoicesInLastYear'}
            label={'Number of invoices in (in 2021.)'}
            type={'text'}
            name={'invoicesInLastYear'}
            register={register}
            icon={<CoinsIcon />}
            state={{ required: false }}

            // TODO: Add Disabled State and Error State
          />
          <FormFieldInput
            id={'invoicesOutLastYear'}
            label={'Number of invoices out (in 2021.)'}
            type={'text'}
            name={'invoicesOutLastYear'}
            register={register}
            icon={<CoinsIcon />}
            state={{ required: false }}

            // TODO: Add Disabled State and Error State
          />
        </div>

        {/* <div className={styles.UpdateOrganisationStep__row}>
          <FormFieldInput
            id={'numberOfEmployees'}
            label={'Number of employees'}
            type={'number'}
            name={'numberOfEmployees'}
            register={register}
            icon={<PersonsIcon />}
            state={{ required: false }}

            // TODO: Add Disabled State and Error State
          />

          <FormFieldInput
            id={'turnover'}
            label={'Turnover'}
            type={'text'}
            name={'turnover'}
            register={register}
            icon={<CoinsIcon />}
            state={{ required: false }}

            // TODO: Add Disabled State and Error State
          />
        </div> */}
        <FormFieldCta
          className={styles.UpdateOrganisationStep__submitBtn}
          type='submit'
          id={'save'}
          label={'Save and close'}
        />
      </div>
    </section>
  );
};
