import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Heading } from '@/components/1-atoms';
import { Dropdown, IconButton } from '@/components/2-molecules';
import { useAppSelector } from '@/store';
import { ReactComponent as ArrowsIcon } from '@/assets/Icons/icon-arrows.svg';
import { ReactComponent as SearchIcon } from '@/assets/Icons/icon-search-border.svg';
import { ReactComponent as NotificationIcon } from '@/assets/Icons/icon-notification.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/Icons/icon-arrow-accordion.svg';
import { ReactComponent as ProfileIcon } from '@/assets/Icons/icon-person-profile.svg';

import styles from './Header.module.scss';
import { clearLocalStorage } from '@/utilities/localStorageUtils';
import { SwapActiveOrganisation } from '@/services/OrganisationServices';
import { useCustomerProfile } from '@/hooks/useCustomerProfile';
import { clearCookie } from '@/utilities/cookieUtils';

export const Header = () => {
  const { dispatchCustomerProfile } = useCustomerProfile();

  const { logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const headerTitle = useAppSelector((state) => state.header?.title);

  const { activeOrg, loginProfile } = useAppSelector((state) => state.user) ?? {};

  const { availableOrganisations: availableOrgs, user } = loginProfile ?? {};

  const { firstName, lastName, userId } = user ?? {};

  const pathPrefix = location.pathname.includes('/leads')
    ? 'leads'
    : location.pathname.includes('/tenders')
    ? 'tenders'
    : undefined;

  const handleProfileOption = (value: string) => {
    console.log(value);

    switch (value) {
      case 'logout': {
        clearLocalStorage();
        clearCookie();
        logout();
        break;
      }
      case 'settings': {
        navigate(`/${pathPrefix}/settings/account`);
        break;
      }
    }
  };

  const handleOrganisationChanged = (orgId: string) => {
    if (!userId) return; // TODO: handle error

    SwapActiveOrganisation(userId, Number(orgId)).then(() => {
      dispatchCustomerProfile();
      navigate(0);
    });
  };

  return (
    <nav className={styles.Header}>
      <Heading headingLevel={'h2'} className={styles.Header__title}>
        {headerTitle}
      </Heading>
      <div className={styles.Header__details}>
        <Dropdown
          className={styles.Header__organisationDropdown}
          options={
            availableOrgs?.map((org) => ({ value: org.id.toString(), label: org.companyName })) ??
            []
          }
          onChange={handleOrganisationChanged}
        >
          <>
            <Heading headingLevel={'h3'} className={styles.Header__activeCompany}>
              <span className={styles.Header__activeCompany__black}>Active Co.: </span>
              {activeOrg?.companyName}
            </Heading>
            <ArrowsIcon />
          </>
        </Dropdown>
        <span className={styles.Header__details__divider} />
        <SearchIcon />
        <span className={styles.Header__details__divider} />
        <NotificationIcon />
        <span className={styles.Header__details__divider} />
        <Dropdown
          className={styles.Header__details__profileBtn}
          options={[
            { value: 'settings', label: 'Settings' },
            { value: 'logout', label: 'Log out' },
          ]}
          onChange={handleProfileOption}
        >
          <>
            <IconButton icon={<ProfileIcon />} />
            <Heading headingLevel={'h3'}>
              {firstName} {lastName}
            </Heading>
            <ArrowDownIcon />
          </>
        </Dropdown>
      </div>
    </nav>
  );
};
