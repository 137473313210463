import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import classNames from 'classnames';

import { AnchorLink, Heading, TextBox, StatusText } from '@/components/1-atoms';
import { ContentCard, DataTable, Dropdown, Filters } from '@/components/2-molecules';

import styles from './TendersOverview.module.scss';
import { ReactComponent as MenuIcon } from '@/assets/Icons/icon-menu.svg';
import { deleteTender } from '@/services/TenderServices';

export interface TendersOverviewProps {
  className?: string;
  tendersList: Tender[];
  setTendersList: React.Dispatch<React.SetStateAction<Tender[]>>;
}

export const TendersOverview = ({
  className,
  tendersList,
  setTendersList,
}: TendersOverviewProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('All');
  const navigate = useNavigate();

  const handleFilterOptionChange = (value: string) => {
    setSelectedFilter(value);
  };

  const handleOptionClicked = async (value: string, tenderId: number) => {
    switch (value) {
      case 'viewTender':
        navigate(tenderId.toString());
        break;
      case 'editTender':
        navigate(tenderId.toString());
        break;
      case 'deleteTender':
        try {
          const response = await deleteTender(tenderId);
          if (response.successfulOperation) {
            setTendersList((prevTenders) => prevTenders.filter((tender) => tender.id !== tenderId));
          } else {
            console.error('Failed to delete the tender');
          }
        } catch (error) {
          console.error('Error deleting tender:', error);
        }
        break;
    }
  };

  const getMenuOptions = (tender: Tender) => {
    if (tender.status === 'Draft')
      return [
        { value: 'viewTender', label: 'View tender' },
        { value: 'editTender', label: 'Edit tender' },
        { value: 'deleteTender', label: 'Delete tender' },
      ];

    return [{ value: 'viewTender', label: 'View tender' }];
  };

  const columns = useMemo<ColumnDef<Tender>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'TYPE',
        accessorKey: 'tenderTypeObj.name',
      },
      {
        header: 'START DATE',
        accessorKey: 'startDate',
      },
      {
        header: 'END DATE',
        accessorKey: 'endDate',
      },
      {
        header: 'VALUE (ROI)',
        accessorKey: 'value',
      },
      {
        header: 'STATUS',
        cell: (table: any) => {
          const status = table.row.original.status;
          switch (status) {
            case 'Draft':
              return <StatusText status={'inactive'} text={status} />;
            case 'Active':
              return <StatusText status={'active'} text={status} />;
            case 'Finished':
              return <StatusText status={'finished'} text={status} />;
          }
        },
      },
      {
        header: 'ACTIONS',
        cell: (table: any) => {
          const tender = table.row.original;

          return (
            <Dropdown
              className={styles.TendersOverview__actionsButton}
              options={getMenuOptions(tender)}
              onChange={(option) => handleOptionClicked(option, tender.id)}
            >
              <MenuIcon />
            </Dropdown>
          );
        },
      },
    ],
    [],
  );

  const filteredEvents =
    tendersList?.filter((item) => {
      if (selectedFilter === 'All') return true;
      return item.status === selectedFilter;
    }) || [];

  return (
    <div className={classNames(styles.TendersOverview, className)}>
      <ContentCard
        heading={<Heading headingLevel={'h2'}>Tender overview</Heading>}
        actionButton={
          <div className={styles.TendersOverview__filters}>
            <Filters
              onChange={handleFilterOptionChange}
              options={[
                {
                  label: 'All',
                  value: 'All',
                  className: styles.TendersOverview__tendersFilter,
                  activeClassName: styles.TendersOverview__tendersFilter_active,
                },
                {
                  label: 'Active',
                  value: 'Active',
                  className: styles.TendersOverview__tendersFilter,
                  activeClassName: styles.TendersOverview__tendersFilter_active,
                },
                {
                  label: 'Finished',
                  value: 'Finished',
                  className: styles.TendersOverview__tendersFilter,
                  activeClassName: styles.TendersOverview__tendersFilter_active,
                },
                {
                  label: 'Drafts',
                  value: 'Draft',
                  className: styles.TendersOverview__tendersFilter,
                  activeClassName: styles.TendersOverview__tendersFilter_active,
                },
              ]}
              selectedValue={selectedFilter}
              type={'spread'}
            />
          </div>
        }
      >
        <DataTable
          columns={columns}
          data={filteredEvents}
          onRowClick={(tender) => {
            navigate(tender.id.toString());
          }}
        />

        {!filteredEvents?.length && (
          <div className={styles.TendersOverview__emptyList}>
            <TextBox
              title={
                <Heading headingLevel={'h3'} className={styles.TendersOverview__emptyListHeading}>
                  There are no tenders for this company.
                </Heading>
              }
              description={
                <>
                  Not sure how to get started? Try{' '}
                  <AnchorLink link={''}>
                    <>
                      watching our video <br /> guide
                    </>
                  </AnchorLink>{' '}
                  or <AnchorLink link={''}> send as a question. </AnchorLink>
                </>
              }
              alignment={'centre'}
            />
          </div>
        )}
      </ContentCard>
    </div>
  );
};
